<template>
  <v-list>
    <v-dialog v-if="!hideAssets" width="1000" persistent :disabled="!hasOrganizationWithScope(AuthScope.CAN_CREATE_ASSETS)">
      <template #activator="{ props: dialogProps }">
        <v-list-item
          v-bind="dialogProps"
          prepend-icon="add"
          :title="$t('assets.asset')"
          :disabled="!hasOrganizationWithScope(AuthScope.CAN_CREATE_ASSETS)"
        />
      </template>

      <template #default="{ isActive }">
        <asset-form
          @ok="
            (asset: Asset) => {
              isActive.value = false
              emit('ok')
              $router.push(`/assets/${asset._id}`)
            }
          "
          @cancel="
            () => {
              isActive.value = false
            }
          "
        />
      </template>
    </v-dialog>

    <v-dialog
      v-if="!allowedTicketTypes || allowedTicketTypes.includes(TicketType.INSPECTION)"
      width="1000"
      persistent
      :disabled="!hasOrganizationWithScope(AuthScope.CAN_CREATE_INSPECTIONS)"
    >
      <template #activator="{ props: dialogProps }">
        <v-list-item
          v-bind="dialogProps"
          prepend-icon="add"
          :title="$t('inspections.inspection')"
          :disabled="!hasOrganizationWithScope(AuthScope.CAN_CREATE_INSPECTIONS)"
        />
      </template>

      <template #default="{ isActive }">
        <dialog-ticket
          :ticket-type="TicketType.INSPECTION"
          @ok="
            () => {
              isActive.value = false
              emit('ok')
            }
          "
          @cancel="
            () => {
              isActive.value = false
            }
          "
        />
      </template>
    </v-dialog>

    <v-dialog
      v-if="isNenEnabled && (!allowedTicketTypes || allowedTicketTypes.includes(TicketType.NEN3140))"
      width="1000"
      persistent
      :disabled="!hasOrganizationWithScope(AuthScope.CAN_CREATE_INSPECTIONS)"
    >
      <template #activator="{ props: dialogProps }">
        <v-list-item
          v-bind="dialogProps"
          prepend-icon="add"
          :title="$t('nen3140s.nen3140')"
          :disabled="!hasOrganizationWithScope(AuthScope.CAN_CREATE_INSPECTIONS)"
        />
      </template>

      <template #default="{ isActive }">
        <dialog-ticket
          :ticket-type="TicketType.NEN3140"
          @ok="
            () => {
              isActive.value = false
              emit('ok')
            }
          "
          @cancel="
            () => {
              isActive.value = false
            }
          "
        />
      </template>
    </v-dialog>

    <v-dialog
      v-if="isNenEnabled && (!allowedTicketTypes || allowedTicketTypes.includes(TicketType.NEN1010))"
      width="1000"
      persistent
      :disabled="!hasOrganizationWithScope(AuthScope.CAN_CREATE_INSPECTIONS)"
    >
      <template #activator="{ props: dialogProps }">
        <v-list-item
          v-bind="dialogProps"
          prepend-icon="add"
          :title="$t('nen1010s.nen1010')"
          :disabled="!hasOrganizationWithScope(AuthScope.CAN_CREATE_INSPECTIONS)"
        />
      </template>

      <template #default="{ isActive }">
        <dialog-ticket
          :ticket-type="TicketType.NEN1010"
          @ok="
            () => {
              isActive.value = false
              emit('ok')
            }
          "
          @cancel="
            () => {
              isActive.value = false
            }
          "
        />
      </template>
    </v-dialog>

    <v-dialog
      v-if="!allowedTicketTypes || allowedTicketTypes.includes(TicketType.MALFUNCTION)"
      width="1000"
      persistent
      :disabled="!hasOrganizationWithScope(AuthScope.CAN_CREATE_MALFUNCTIONS)"
    >
      <template #activator="{ props: dialogProps }">
        <v-list-item
          v-bind="dialogProps"
          prepend-icon="add"
          :title="$t('malfunctions.malfunction')"
          :disabled="!hasOrganizationWithScope(AuthScope.CAN_CREATE_MALFUNCTIONS)"
        />
      </template>

      <template #default="{ isActive }">
        <dialog-ticket
          :ticket-type="TicketType.MALFUNCTION"
          @ok="
            () => {
              isActive.value = false
              emit('ok')
            }
          "
          @cancel="
            () => {
              isActive.value = false
            }
          "
        />
      </template>
    </v-dialog>

    <v-dialog
      v-if="!allowedTicketTypes || allowedTicketTypes.includes(TicketType.ACTION)"
      width="1000"
      persistent
      :disabled="!hasOrganizationWithScope(AuthScope.CAN_CREATE_ACTIONS)"
    >
      <template #activator="{ props: dialogProps }">
        <v-list-item
          v-bind="dialogProps"
          prepend-icon="add"
          :title="$t('actions.action')"
          :disabled="!hasOrganizationWithScope(AuthScope.CAN_CREATE_ACTIONS)"
        />
      </template>

      <template #default="{ isActive }">
        <dialog-ticket
          :ticket-type="TicketType.ACTION"
          @ok="
            () => {
              isActive.value = false
              emit('ok')
            }
          "
          @cancel="
            () => {
              isActive.value = false
            }
          "
        />
      </template>
    </v-dialog>
  </v-list>
</template>

<script setup lang="ts">
const $router = useRouter()

const { hasOrganizationWithScope } = useAuthStore()
const { isNenEnabled } = useSettingsStore()

const emit = defineEmits<{
  (e: "ok"): void
}>()

const props = defineProps<{
  hideAssets?: boolean
  allowedTicketTypes?: Array<TicketType>
}>()

const { hideAssets, allowedTicketTypes } = toRefs(props)
</script>
